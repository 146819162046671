import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import AppContext from "../../context/AppContext";
import Alert from "../Alert";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

export default function Profile(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const { alertValue, showAlert, userdata,updateUser,getUser,logError } = context;
  let [fieldDetails, setField] = useState({
    name: userdata.name,
    email: userdata.email,
  });
  let [errorDetails, setErrorsDetails] = useState({
    name: false,
    email: false,
  });
  let [loading, setLoading] = useState(false);
  let [navLoader,setNavLoader] = useState(false);

  // signup functions
  const onSubmit = async () => {
    setLoading(true);
    let emailErr = false;
    let nameErr = false;

    // resetting errors
    setErrorsDetails({
      name: false,
      email: false,
    });

    // validating name field
    if (fieldDetails.name.length <= 1) {
      // enter a valid name
      setErrorsDetails((stat) => {
        return { ...stat, name: true };
      });
      nameErr = true;
    } else {
      nameErr = false;
    }

    if (!nameErr && !emailErr) {
      let updateData = { token: userdata.userid };
      if (fieldDetails.name != userdata.name) {
        updateData = { ...updateData, name: fieldDetails.name };
      }
      if (fieldDetails.email != userdata.email) {
        updateData = { ...updateData, email: fieldDetails.email };
      }
      if (updateData.name || updateData.email) {
        setLoading(true);
        const response = await updateUser(updateData);
        console.log(response);
        if (response.response == 'success') {
          if (response.message == 'Account details are updated') {
            showAlert("green","Details updated successfully", 3000);
          }
        }
        if(response.response == 'error'){
          if (response.message == 'Invalid email') {
            setErrorsDetails((stat) => {
              return { ...stat, email: true };
            });
          }
        }
        setLoading(false);
      } else {
        showAlert('green','Nothing to updated',3000);
      }
    }
    setLoading(false);
  };
  const onchange=(e)=>{
    setField((stat)=>{
      return {...stat,[e.target.id] : e.target.value};
    })
  }
  
  useEffect(()=>{
    if (!userdata.loggedin) {
      let user = getUser();
      if (!user) {
        setNavLoader(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 3000);
      }
    }
    setField({
      name: userdata.name,
      email: userdata.email,
    });
  },[userdata])
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
      <title>Shortwash - Profile</title>
      </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div style={{ width: "100%", minheight: "100%" }} className="d-flex justify-content-end profile-outer-wrapper">
          {!navLoader && <div className="profile-wrapper"
            style={{
              backgroundColor: "white",
              minheight:'100%'
            }}
          >
            <div className="text-center profile-header" style={{marginTop:20}}>
              <span
                style={{
                  fontSize: 32,
                  textAlign: "center",
                  fontWeight: "300",
                }}
              >
                Profile
              </span>
            </div>
            <div
              className="profile-bg simpleflex"
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <img
                style={{ width: "70%", height: 200 }}
                src={require("../../assets/auth/profilebg.png")}
              />
            </div>
            <div
              className="form-wrapper"
              style={{
                marginTop: 5,
                marginBottom: 30,
                marginLeft: 30,
                marginRight: 30,
              }}
            >
              <div style={{ position: "relative" }}>
                <span
                  style={{
                    color: errorDetails.name ? "red" : "grey",
                    fontSize: 15,
                    position: "absolute",
                    top: -11,
                    left: 12,
                    backgroundColor: "white",
                    paddingLeft:6,
                    paddingRight:6,
                    zIndex: 1,
                  }}
                >
                  Name
                </span>
                <input
                  style={{
                    borderColor: errorDetails.name ? "red" : "rgb(225,225,225)",
                    borderRadius: 6,
                    borderWidth: 1,
                    marginTop: 6,
                    fontSize: 16,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                  placeholder="Your sweet name"
                  type="text"
                  id="name"
                  className="form-control"
                  value={fieldDetails.name}
                  onChange={onchange}
                />
              </div>

              <div
                style={{
                  visibility: errorDetails.name ? "visible" : "hidden",
                  flexDirection: "row",
                  color: "red",
                  marginTop: 5,
                  alignItems: "center",
                }}
                className='d-flex'
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5,
                  }}
                  src={require("../../assets/login/info_red.png")}
                />
                <span style={{ color: "red" }}>Invalid name</span>
              </div>

            

              <div style={{ position: "relative", marginTop: 15 }}>
                <span
                  style={{
                    color: "grey",
                    fontSize: 15,
                    position: "absolute",
                    top: -11,
                    backgroundColor: "white",
                    paddingLeft:6,
                    paddingRight:6,
                    zIndex: 1,
                    left: 12,
                  }}
                >
                  Mobile
                </span>
                <input
                  style={{
                    borderColor:
                      errorDetails.mobileLen || errorDetails.mobileUser
                        ? "red"
                        : "rgb(225,225,225)",
                    borderRadius: 6,
                    borderWidth: 1,
                    marginTop: 6,
                    color: "grey",
                    fontSize: 16,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                  type="number"
                  className="form-control"
                  value={userdata.mobile}
                  disabled
                />
                <span style={{ color: "grey", marginTop: 5, marginLeft: 5 }}>
                  You can't change your mobile number
                </span>
              </div>

              <div className="text-center">
              <button
                style={{
                  backgroundColor: "red",
                  marginTop: 30,
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderRadius: 5,
                }}
                className="btn profile-submit-btn"
                onClick={() => {
                  onSubmit();
                }}
                disabled={loading ? true : false}
              >
                <span
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 14,
                  }}
                >
                  {loading ? <Spinner size="24" borderColor="#ffffff"/> : 'Update'}
                </span>
              </button>
              </div>
            </div>
          </div>}
          {navLoader && <div className="simpleflex" style={{height:'100%',width:'100%',backgroundColor : 'white'}}>
          <Spinner borderColor="#000000" size="28px"/>
          </div>}
        </div>

      </div>
    </React.Fragment>
  );
}
