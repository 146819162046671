import Navbar from "./Navbar";
import React, { useContext, useState } from "react";
import Footer from "./Footer"

export default function Contact(props) {
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Navbar />
        <div
          style={{
            backgroundColor: "white",
          }}
        >
          <div
            className="d-flex justify-content-end contact-container"
            style={{ height: "calc(100vh - 86px)" }}
          >
            <div className="contact-wrapper simpleflex" style={{backgroundColor:'white' }}>
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  className="simpleflex"
                >
                  <span style={{ fontSize: 32, fontWeight: "300" }}>
                    Get in touch!
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  className="simpleflex d-none"
                >
                  <img
                    style={{ width: "70%", height: 200 }}
                    src={require("../assets/contact/contactusbg.png")}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <div
                    className="c-row"
                    style={{
                      flexDirection: "row",
                      marginVertical: 10,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        style={{ height: 32, width: 32 }}
                        src={require("../assets/contact/email.png")}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: "grey",
                          fontWeight: "500",
                          fontSize: 15,
                        }}
                      >
                        Email
                      </span>
                      <span style={{ fontSize: 16 }}>
                        contact@shortwash.com
                      </span>
                    </div>
                  </div>
                  <div
                    className="c-row"
                    style={{
                      flexDirection: "row",
                      marginVertical: 10,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        style={{ height: 32, width: 32 }}
                        src={require("../assets/contact/contact-phone.png")}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: "grey",
                          fontWeight: "500",
                          fontSize: 15,
                        }}
                      >
                        Mobile
                      </span>
                      <span style={{ fontSize: 16 }}>7838106525</span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginTop: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <span
                    style={{
                      color: "grey",
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                  >
                    Our customer support team will help you as soon as possible,
                    don't hesitate to reach us!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
