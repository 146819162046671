import React, { useContext, useState } from "react";

import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Alert from "./Alert";
import { Helmet } from "react-helmet";

export default function Refer() {
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <script>
            window.location.href = "https://shortwash.com/";
          </script>
          <title>
            Shortwash - Refer your friend and get &#8377;50 in your wallet
          </title>
        </Helmet>
        <Navbar page="refer" />
          <div className="simpleflex">
            <a className="text-primary" href="https://shortwash.com/">We had temporarily closed referral program.</a>
          </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
