import React, { useState, useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import app from "../../firebaseConfig";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Alert from "../Alert";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

export default function ForgotPassword() {
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <script>window.location.href = "https://shortwash.com/auth/login"</script>
          <title>Shortwash - Forgot your password</title>
        </Helmet>
        <Navbar page="auth" />
        <div className="simpleflex">
          <div className="simpleflex">
            <a className="text-primary" href="https://shortwash.com/auth/login">
              We had temporarily removed forgot password page.
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
