import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AppContext from "../../context/AppContext";
import Alert from "../Alert";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";
export default function Login(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const { loginUser, setUserData, userdata, showAlert, alertValue, logError } =
    context;
  // login's state
  let [loginUserDetails, setLoginUserDetails] = useState({
    mobile: "",
    otpVal: "",
    name: "",
  });
  let [errorValue, setErrorsValue] = useState({
    mobileLen: false,
    invalidOtp: false,
    nameLen: false,
  });
  let [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState(null);
  const [loginStage, setLoginStage] = useState(1);
  const [userStatus, setUserStatus] = useState(true);
  // functions for login
  useEffect(() => {
    if (userdata.loggedin) {
      navigate("/");
    }
  }, [userdata]);
  const submitMobile = async () => {
    setErrorsValue(function (state) {
      return {
        ...state,
        mobileLen: false,
      };
    });

    let mobileErr = false;
    if (
      loginUserDetails.mobile.length > 10 ||
      loginUserDetails.mobile.length < 10
    ) {
      setErrorsValue(function (val) {
        return { ...val, mobileLen: true };
      });
      mobileErr = true;
    } else {
      setErrorsValue(function (val) {
        return { ...val, mobileLen: false };
      });
      mobileErr = false;
    }

    if (!mobileErr) {
      let response = await fetch(
        "https://expressv2.shortwash.com/login/checkuser.php",
        {
          method: "POST",
          body: JSON.stringify({ mobile: loginUserDetails.mobile }),
        }
      );
      let responseData = await response.json();
      if (responseData.response == "success") {
        setUserStatus(true);
        sendOtp();
      } else {
        setUserStatus(false);
        sendOtp();
      }
    }
  };
  const verifyOTP = async () => {
    setLoading(true);
    setErrorsValue(function (state) {
      return {
        ...state,
        invalidOtp: false,
        nameLen: false,
      };
    });

    let otpErr = false;
    let nameErr = false;
    if (
      loginUserDetails.otpVal.length > 6 ||
      loginUserDetails.otpVal.length < 6
    ) {
      setErrorsValue(function (val) {
        return { ...val, invalidOtp: true };
      });
      otpErr = true;
    } else {
      setErrorsValue(function (val) {
        return { ...val, invalidOtp: false };
      });
      otpErr = false;
    }

    if (!userStatus) {
      if (loginUserDetails.name.length < 3) {
        setErrorsValue(function (val) {
          return { ...val, nameLen: true };
        });
        nameErr = true;
      } else {
        setErrorsValue(function (val) {
          return { ...val, nameLen: false };
        });
        nameErr = false;
      }
    }

    if (!otpErr && !nameErr) {
      if (loginUserDetails.otpVal == otp) {
        
        let response = await loginUser(
          loginUserDetails.mobile,
          loginUserDetails.name
        );
        if (response.response == "success") {
          let name = response.data.name;
          setUserData({
            loggedin: true,
            email: response.data.email,
            mobile: response.data.mobile,
            name: response.data.name,
            userid: response.data.userid,
          });
          let jsonUserData = JSON.stringify({
            loggedin: true,
            email: response.data.email,
            mobile: response.data.mobile,
            name: response.data.name,
            userid: response.data.userid,
          });
          window.sessionStorage.setItem("userdata", jsonUserData);
          showAlert(
            "green",
            `Welcome ${name}, what would you like to do today?`,
            3000
          );
          navigate("/");
        }
      } else {
        // invalid otp
        setErrorsValue(function (val) {
          return { ...val, invalidOtp: true };
        });
      }
    }
    setLoading(false);
  };
  const onchange = (e) => {
    if (e.target.id == "mobile") {
      if (e.target.value.length <= 10) {
        setLoginUserDetails((stat) => {
          return { ...stat, [e.target.id]: e.target.value };
        });
      }
    } else {
      setLoginUserDetails((stat) => {
        return { ...stat, [e.target.id]: e.target.value };
      });
    }
  };
  async function sendOtp() {
    setLoading(true);
    let d1 = Math.ceil(Math.random() * 9);
    let d2 = Math.ceil(Math.random() * 9);
    let d3 = Math.ceil(Math.random() * 9);
    let d4 = Math.ceil(Math.random() * 9);
    let d5 = Math.ceil(Math.random() * 9);
    let d6 = Math.ceil(Math.random() * 9);

    let otpNum = `${d1}${d2}${d3}${d4}${d5}${d6}`;
    setOTP(otpNum);
    // console.log("otp is " + otpNum);
    // setLoginStage(2);
    // showAlert(
    //   "green",
    //   `OTP sent successfully at +91${loginUserDetails.mobile}`,
    //   5000
    // );
    const OTPSent = await fetch(
      "https://expressv2.shortwash.com/sms/sendsms.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: otpNum,
          mobile: loginUserDetails.mobile,
        }),
      }
    );
    let otpResponse = await OTPSent.json();
    if (otpResponse.return) {
      if (otpResponse.message == "SMS sent successfully.") {
        setLoginStage(2);
        showAlert(
          "green",
          `OTP sent successfully at +91${loginUserDetails.mobile}`,
          5000
        );
      } else {
        setOTP(null);
        showAlert(
          "light",
          "Something went wrong, please complaint at complaint@shortwash.com",
          3000
        );
      }
    } else {
      setOTP(null);
      showAlert(
        "light",
        "Something went wrong, please complaint at complaint@shortwash.com",
        3000
      );
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <title>Shortwash - Login in your account</title>
        </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div className="login-wrapper d-flex justify-content-end">
          <div
            className="container login-inner-wrapper text-center"
            style={{ paddingBottom: 40 }}
          >
            <div
              className="login-header simpleflex"
              style={{ flexDirection: "column" }}
            >
              <span
                style={{
                  fontSize: 32,
                  fontWeight: "300",
                  textAlign: "center",
                  color: "black",
                  marginTop: 20,
                }}
              >
                Login to continue
              </span>
              <span
                style={{ marginTop: 5, color: "grey", textAlign: "center" }}
              >
                you can reach us anytime at help@shortwash.com
              </span>
            </div>
            <div
              className="loginbg"
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <img
                style={{ width: "70%", height: 200 }}
                src={require("../../assets/login/loginbg.png")}
              />
            </div>
            <div
              className="login-form-wrapper"
              style={{
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <div style={{ position: "relative" }}>
                <label
                  style={{
                    fontSize: 16,
                    position: "absolute",
                    backgroundColor: "white",
                    zIndex: 1,
                    top: -11,
                    left: 12,
                    paddingLeft: 6,
                    paddingRight: 6,
                    color: errorValue.mobileLen ? "red" : "grey",
                  }}
                >
                  Mobile Number
                </label>
                <input
                  className="form-control login-mobile-inp"
                  style={{
                    borderColor: errorValue.mobileLen
                      ? "red"
                      : "rgb(225,225,225)",
                    borderWidth: 1,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    fontSize: 16,
                    borderRadius: 6,
                  }}
                  id="mobile"
                  placeholder="Your mobile number here"
                  type="number"
                  value={loginUserDetails.mobile}
                  onChange={onchange}
                />
              </div>
              <div
                style={{
                  color: "red",
                  marginTop: 5,
                  visibility: `${errorValue.mobileLen ? "visible" : "hidden"}`,
                  alignItems: "center",
                  flexDirection: "row",
                }}
                className="d-flex"
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5,
                    marginTop: 5,
                    marginBottom: 7,
                  }}
                  src={require("../../assets/login/info_red.png")}
                />
                {errorValue.mobileLen && (
                  <span style={{ color: "red" }}>Invalid mobile number</span>
                )}
              </div>

              {!userStatus && (
                <React.Fragment>
                  <div style={{ position: "relative" }}>
                    <label
                      style={{
                        fontSize: 16,
                        position: "absolute",
                        backgroundColor: "white",
                        zIndex: 1,
                        top: -11,
                        left: 12,
                        paddingLeft: 6,
                        paddingRight: 6,
                        color: errorValue.nameLen ? "red" : "grey",
                      }}
                    >
                      Name
                    </label>
                    <input
                      className="form-control login-mobile-inp"
                      style={{
                        borderColor: errorValue.nameLen
                          ? "red"
                          : "rgb(225,225,225)",
                        borderWidth: 1,
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "black",
                        fontSize: 16,
                        borderRadius: 6,
                      }}
                      id="name"
                      placeholder="Your sweet name"
                      type="text"
                      value={loginUserDetails.name}
                      onChange={onchange}
                    />
                  </div>
                  <div
                    style={{
                      color: "red",
                      marginTop: 5,
                      visibility: `${
                        errorValue.nameLen ? "visible" : "hidden"
                      }`,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                    className="d-flex"
                  >
                    <img
                      style={{
                        height: 12,
                        width: 12,
                        marginRight: 5,
                        marginTop: 5,
                        marginBottom: 7,
                      }}
                      src={require("../../assets/login/info_red.png")}
                    />
                    {errorValue.nameLen && (
                      <span style={{ color: "red" }}>Invalid Name</span>
                    )}
                  </div>
                </React.Fragment>
              )}

              {loginStage == 2 && (
                <React.Fragment>
                  <div style={{ position: "relative", marginTop: 25 }}>
                    <label
                      style={{
                        fontSize: 16,
                        position: "absolute",
                        backgroundColor: "white",
                        zIndex: 1,
                        top: -11,
                        left: 12,
                        paddingLeft: 6,
                        paddingRight: 6,
                        color: errorValue.invalidOtp ? "red" : "grey",
                      }}
                    >
                      OTP
                    </label>
                    <input
                      className="form-control login-password-inp"
                      style={{
                        borderColor: `${
                          errorValue.invalidOtp ? "red" : "rgb(225,225,225)"
                        }`,
                        borderWidth: 1,
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "black",
                        fontSize: 16,
                        borderRadius: 6,
                      }}
                      id="otpVal"
                      placeholder="Enter 6 digit OTP"
                      type="number"
                      value={loginUserDetails.otpVal}
                      onChange={onchange}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      visibility: `${
                        errorValue.invalidOtp ? "visible" : "hidden"
                      }`,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                    className="d-flex"
                  >
                    <img
                      style={{ height: 12, width: 12, marginRight: 5 }}
                      src={require("../../assets/login/info_red.png")}
                    />
                    <span style={{ color: "red" }}>Invalid OTP</span>
                  </div>
                </React.Fragment>
              )}

              <div
                className="login-btn-wrapper simpleflex"
                style={{ flexDirection: "column" }}
              >
                <button
                  style={{
                    backgroundColor: "red",
                    marginTop: 20,
                    paddingVertical: 8,
                    borderRadius: 5,
                  }}
                  className="btn w-100"
                  onClick={() => {
                    if (loginStage == 1) {
                      submitMobile();
                    }
                    if (loginStage == 2) {
                      verifyOTP();
                    }
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: 17,
                    }}
                  >
                    {!loading && loginStage == 1 && "Get OTP"}
                    {!loading && loginStage == 2 && "Verify OTP"}
                    {loading && <Spinner size="25" borderColor="#ffffff" />}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
