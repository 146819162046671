import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";

export default function Navbar({ page }) {
  const context = useContext(AppContext);
  const { userdata, setUserData, showAlert } = context;
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative" }}>
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        style={{ backgroundColor: "black" }}
      >
        <div className="container-fluid">
          <img
            src={require("../assets/logo.png")}
            alt="Shortwash Logo"
            height="70px"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item px-3">
                <span
                  className={`nav-link navbarlink nav-job-a ${
                    page == "home" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/home");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </span>
              </li>
              <li className="nav-item px-3">
                <span
                  className={`nav-link navbarlink nav-job-a ${
                    page == "reviews" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/reviews");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Reviews
                </span>
              </li>
              <li className="nav-item px-3">
                <a
                  className={`nav-link navbarlink nav-job-a ${
                    page == "contact" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/contact");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Contact
                </a>
              </li>
              {!userdata.loggedin && (
                <li className="nav-item px-3">
                  <span
                    className={`nav-link navbarlink nav-job-a ${
                      page == "auth" ? "active" : ""
                    }`}
                    aria-current="page"
                    onClick={() => {
                      navigate("/auth/login");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Login/Signup
                  </span>
                </li>
              )}
              {userdata.loggedin && (
                <li className="nav-item dropdown px-3">
                  <span
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userdata.loggedin && `Welcome ${userdata.name}`}
                  </span>
                  <ul className="dropdown-menu">
                    <li
                      onClick={() => {
                        navigate("/auth/profile");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        Profile
                      </span>
                    </li>
                    <li
                      onClick={() => {
                        navigate("/auth/orders");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        Orders
                      </span>
                    </li>
                    {/*<li
                      onClick={() => {
                        navigate("/auth/wallet");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        Wallet
                      </span>
                    </li> */}
                    <li
                      onClick={() => {
                        navigate("/auth/address");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        Address
                      </span>
                    </li>
                    <li
                      onClick={() => {
                        setUserData({
                          loggedin: false,
                        });
                        window.sessionStorage.clear();
                        showAlert(
                          "green",
                          "Account logged out successfully",
                          3000
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="dropdown-item">Logout</span>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
