import React, { useState } from "react";
import app from "../../firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Alert from "../Alert";
import Spinner from "../Spinner";
import Footer from "../Footer";
import { logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";

export default function Signup(props) {
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
        <script>
        window.location.href = "https://shortwash.com/auth/login"
        </script>
          <title>
            Shortwash - Create an account and enjoy our premium laundry service
          </title>
        </Helmet>
        <Navbar page="auth" />
        <div className="simpleflex">
          <a className="text-primary" href="https://shortwash.com/auth/login">
            We had temporarily removed signup page.
          </a>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
