import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderStage from "./OrderStage";
import AppContext from "../../../context/AppContext";
import Spinner from "../../Spinner";

export default function MainOrder(props) {
  const context = useContext(AppContext);
  const { userdata, showAlert } = context;
  const [orderDetails, setOrderDetails] = useState(null);
  var base64 = require("base-64");
  var [loading, setLoading] = useState(false);


  useEffect(() => {
    let userid = userdata.userid;
    let oid = props.order.order.orderid;
    fetchOrder(userid, oid);
  }, []);
  async function fetchOrder(userid, oid) {
    setLoading(true);
    let response = await fetch(
      "https://expressv2.shortwash.com/orders/getspecificorder.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: userid,
          orderid: oid,
        }),
      }
    );
    let responseData = await response.json();
    if (responseData.response == "success") {
      setOrderDetails(responseData.data[0]);
    } else {
      setOrderDetails(null);
    }
    setLoading(false);
  }
  const navigate = useNavigate();
  function formatDate() {
    let date = props.order.order.order_date.substr(0, 10);
    let dateArr = date.split("-");
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let finalDate = `${month[dateArr[1] - 1]} ${dateArr[2]}, ${dateArr[0]}`;
    return finalDate;
  }
  function etaCalc() {
    let washingstage = props.order.status;
    let eta;
    // pickup = 3,queue = 2, washing = 6,delivery = 3
    switch (washingstage) {
      case "pickup":
        eta = "Not Updated";
        break;
      case "queue":
        eta = "14-16 Hour";
        break;
      case "washing":
        eta = "11-13 Hour";
        break;
      case "delivery":
        eta = "3-5 Hour";
        break;
      case "delivered":
        eta = "Delivered";
        break;
      default:
        eta = "Not Updated";
        break;
    }
    return eta;
  }
  async function getPaymentPage(mtid, muid, amount, mobile) {
    try {
      let response = await fetch(
        "https://expressv2.shortwash.com/payments/getpaypage.php",
        {
          method: "POST",
          body: JSON.stringify({
            mtid,
            muid,
            amount,
            mobile
            
          }),
        }
      );
      let responseData = await response.json();
      if (responseData.success) {
        showAlert("green", "Payment initiated successfully", 5000);
        window.location.href =
          responseData.data.instrumentResponse.redirectInfo.url;
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }
  return (
    <div
      style={{
        width: "92%",
        marginLeft: "4%",
        marginRight: "4%",
        borderColor: "#E1E1E1",
        borderWidth: 2,
        padding: "2%",
        borderStyle: "solid",
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 15,
      }}
      className="ordercontainer"
    >
      {loading && (
        <div className="simpleflex" style={{ height: "227px" }}>
          <Spinner borderColor="#000000" size="28px" />
        </div>
      )}
      {!loading && orderDetails && (
        <>
          {props.order.status != "pickup" &&
            orderDetails.payment_status == "Pending" && (
              <div
                style={{
                  flexDirection: "row",
                  borderColor: "#e1e1e1",
                  borderBottomWidth: 1,
                  paddingBottom: "1%",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderColor: "#e1e1e1",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setLoading(true);
                    let datePart = Date.now();
                    let tId = `SW-${props.order.order.orderid}-${datePart}`;
                    
                    getPaymentPage(
                      tId,
                      userdata.userid,
                      parseFloat(orderDetails.total_amount) * 100,
                      userdata.mobile
                    );
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span>Pay Now</span>
                    <img
                      style={{ width: 16, height: 16, marginLeft: 3 }}
                      src={require("../../../assets/orders/payforthisorder.png")}
                    />
                  </div>
                </div>
              </div>
            )}
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <span>
              <span style={{ color: "grey", fontWeight: "600" }}>Date - </span>
              {formatDate()}
            </span>
          </div>
          <div>
            <span style={{ marginTop: 5, marginBottom: 5 }}>
              <span style={{ color: "grey", fontWeight: "600" }}>
                Expected Time -{" "}
              </span>
              {etaCalc()}
            </span>
          </div>

          <OrderStage order={props.order} />

          <div
            style={{
              flexDirection: "row",
              borderColor: "#e1e1e1",
              borderTopWidth: 1,
              paddingTop: "1%",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderColor: "#e1e1e1",
                width: "100%",
                display: "flex",
                paddingTop: 5,
                paddingBottom: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/auth/orders/vieworder/${props.order.order.orderid}`);
              }}
            >
              <div style={{ flexDirection: "row" }}>
                <span>View Details</span>
                <img
                  style={{ width: 16, height: 16 }}
                  src={require("../../../assets/orders/next.png")}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
